

























import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'change',
  },
})
export default class CheckboxInputBox extends Vue {
  @Prop() value: any;
  @Prop() modelValue: any;
  @Prop({ default: true }) trueValue: boolean;
  @Prop({ default: false }) falseValue: boolean;

  get isChecked() {
    if (this.modelValue instanceof Array) {
      return this.modelValue.includes(this.value);
    }

    return this.modelValue === this.trueValue;
  }

  emitInput(event) {
    let isChecked = event.target.checked;

    if (this.modelValue instanceof Array) {
      let newValue = [...this.modelValue];

      isChecked ? newValue.push(this.value) : newValue.splice(newValue.indexOf(this.value), 1);

      this.$emit('change', newValue);
    } else {
      this.$emit('change', isChecked ? this.trueValue : this.falseValue);
    }
  }
}
